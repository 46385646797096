/**
 * Created by mac on 5/6/17.
 */
cleverapps.styles.ShineView.zIndex = -1000;

cleverapps.overrideColors(cleverapps.styles.COLORS, {
    MOVES_DANGER: new cc.Color(255, 0, 0, 255),
    PATH_COLOR: new cc.Color(255, 230, 180, 255),
    COLOR_BROWN: new cc.Color(94, 24, 89, 255),
    COLOR_GRAY: new cc.Color(220, 220, 220, 255),
    COLOR_GOLD: new cc.Color(244, 233, 107, 255),
    COLOR_GOLD_DARK: new cc.Color(137, 2, 29, 255),
    LETTER: new cc.Color(94, 24, 89, 255),
    COLOR_LIGHTBROWN: new cc.Color(200, 171, 124, 255),
    COLOR_VICTORY: new cc.Color(253, 217, 61, 255),

    GOAL_NOT_DONE_COLOR: new cc.Color(255, 100, 100, 255),

    COLOR_SWITCH_BUTTON_OFF: new cc.Color(255, 255, 255, 255),

    HARD: new cc.Color(255, 140, 140, 255),

    YELLOW: new cc.Color(255, 216, 0, 255)
});

if (typeof ColorComponent !== "undefined") {
    cleverapps.styles.COLORS.CELL_COLORS = {};
    cleverapps.styles.COLORS.CELL_COLORS[ColorComponent.COLOR_A] = new cc.Color(41, 213, 237, 255);
    cleverapps.styles.COLORS.CELL_COLORS[ColorComponent.COLOR_B] = new cc.Color(54, 208, 27, 255);
    cleverapps.styles.COLORS.CELL_COLORS[ColorComponent.COLOR_C] = new cc.Color(195, 61, 225, 255);
    cleverapps.styles.COLORS.CELL_COLORS[ColorComponent.COLOR_D] = new cc.Color(255, 11, 28, 255);
    cleverapps.styles.COLORS.CELL_COLORS[ColorComponent.COLOR_E] = new cc.Color(234, 199, 48, 255);
    cleverapps.styles.COLORS.CELL_COLORS[ColorComponent.COLOR_W] = new cc.Color(255, 255, 255, 255);
}

cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    BROWN_TEXT_STROKE: {
        color: new cc.Color(255, 255, 255, 150),
        size: 2
    },

    RED_TEXT_STROKE: {
        color: cleverapps.styles.COLORS.COLOR_RED,
        size: 2
    },

    GAMESCENE_TEXT_SHADOW: {
        color: cleverapps.styles.COLORS.BLACK,
        direction: cc.size(2, -2),
        blur: 5
    },

    GOLD_STROKE: {
        color: cleverapps.styles.COLORS.COLOR_GOLD_DARK,
        size: 3
    },

    BROWN_LITTLE_STROKE: {
        color: new cc.Color(160, 82, 45, 255),
        size: 1
    },

    PURPLE_STROKE: {
        color: cleverapps.styles.COLORS.PURPLE,
        size: 6
    },

    PURPLE_SHADOW: {
        color: cleverapps.styles.COLORS.PURPLE,
        direction: cc.size(0, -1),
        blur: 2
    },

    CONGRATS_TEXT_STROKE: {
        color: cleverapps.styles.COLORS.BLACK,
        size: 4
    },

    CONGRATS_TEXT_SHADOW: {
        color: cleverapps.styles.COLORS.BLACK,
        direction: cc.size(1, -8),
        blur: 1
    },

    MOVES_CELL_STROKE: {
        color: cleverapps.styles.COLORS.BLACK,
        size: 3
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    LEVEL_TUTORIAL_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.COLOR_BROWN
    },

    MOVES_TEXT_NORMAL: {
        size: 56,
        color: cleverapps.styles.COLORS.WHITE,
        shadow: cleverapps.styles.DECORATORS.GAMESCENE_TEXT_SHADOW
    },

    X_BOOSTER_AMOUNT: {
        size: 45,
        color: cleverapps.styles.COLORS.WHITE,
        shadow: cleverapps.styles.DECORATORS.WHITE_TEXT_SHADOW
    },

    BOOSTERS_HINT_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE
    },

    EDITOR_RED_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.COLOR_RED
    },

    CONGRATE_TEXT: {
        size: 100,
        color: cleverapps.styles.COLORS.WHITE,
        shadow: cleverapps.styles.DECORATORS.TITLE_TEXT_SHADOW
    },

    EPISODE_TITLE_TEXT: {
        size: 80,
        color: cleverapps.styles.COLORS.COLOR_BROWN
    },

    DESCRIPTION_TEXT: {
        size: 23,
        color: cleverapps.styles.COLORS.WHITE
    },

    REWARDS_TEXT: {
        size: 50
    },

    ADDMOVES_GOAL_RED_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.GOAL_NOT_DONE_COLOR
    },

    SWITCH_BUTTON_ON: {
        size: 44,
        color: cleverapps.styles.COLORS.WHITE,
        shadow: cleverapps.styles.DECORATORS.WHITE_TEXT_SHADOW
    },

    SWITCH_BUTTON_OFF: {
        size: 30,
        color: cleverapps.styles.COLORS.COLOR_SWITCH_BUTTON_OFF,
        shadow: cleverapps.styles.DECORATORS.WHITE_TEXT_SHADOW
    },

    FINISH_EPISODE_TITLE_TEXT: {
        size: 70,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.PURPLE_STROKE
    },

    TOURNAMENTSTORY_OFFERS_TEXT: {
        size: 23,
        color: cleverapps.styles.COLORS.PURPLE
    },

    MINIGAME_HINT_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.PURPLE
    },

    CONGRATS_MESSAGE_FONT: {
        size: 120,
        color: cleverapps.styles.COLORS.CONGRATS_BLUE,
        stroke: cleverapps.styles.DECORATORS.CONGRATS_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.CONGRATS_TEXT_SHADOW
    },

    DEC_MOVES_TEXT: {
        name: "default",
        size: 50,
        color: cleverapps.styles.COLORS.COLOR_RED
    },

    INC_MOVES_TEXT: {
        name: "default",
        size: 50
    },

    FRIDAY_SHOP_TEXT: {
        size: 36,
        color: cleverapps.styles.COLORS.PURPLE
    },

    FRIDAY_SHOP_TITLE: {
        size: 80,
        color: cleverapps.styles.COLORS.CONGRATS_DARK_RED
    },

    MOVES_TEXT: {
        size: 85
    },
    CELL_LIVES: {
        size: 35,
        color: cleverapps.styles.COLORS.RIDDLES_BROWN
    },
    BOMB_TIMER_TEXT: {
        name: "custom_digits",
        color: cleverapps.styles.COLORS.WHITE,
        size: 52
    },
    BOMB_TIMER_DANGER_TEXT: {
        name: "custom_digits",
        size: 55
    },
    PETARD_LIVES: {
        size: 35,
        color: cleverapps.styles.COLORS.WHITE
    },
    GOLDEN_TICKET_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE
    },
    PASS_PROGRESS_POINT_COMPLETED: {
        name: "nostroke",
        size: 36,
        color: cleverapps.styles.COLORS.RIDDLES_BROWN
    }
});

cleverapps.overrideStyles(cleverapps.styles.FieldView, {
    margins: [{
        top: 270,
        bottom: 230,
        left: 10,
        right: 10
    }, {
        top: 270,
        bottom: 30,
        left: 80,
        right: 240
    }, {
        top: 20,
        bottom: 20,
        left: 315,
        right: 280
    }],

    start: {
        animation: {
            position: { dy: 0, dx: 0 }
        }
    },

    delay: 0.6
});

cleverapps.styles.UI.BoostersPositions = [
    [
        { x: { align: "center", dx: -145 }, y: { align: "center" } },
        { x: { align: "center", dx: 35 }, y: { align: "center" } },
        { x: { align: "center", dx: 215 }, y: { align: "center" } }
    ],
    [
        { x: { align: "center", dx: 5 }, y: { align: "center", dy: 190 } },
        { x: { align: "center", dx: 5 }, y: { align: "center", dy: -5 } },
        { x: { align: "center", dx: 5 }, y: { align: "center", dy: -200 } }
    ],
    [
        { x: { align: "center", dx: 5 }, y: { align: "center", dy: 190 } },
        { x: { align: "center", dx: 5 }, y: { align: "center", dy: -5 } },
        { x: { align: "center", dx: 5 }, y: { align: "center", dy: -200 } }
    ]
];

cleverapps.overrideStyles(cleverapps.styles.FieldBoostersView, {
    position: [
        { x: { align: "center", dx: -34 }, y: { align: "center", dy: 10 } },
        { x: { align: "center", dx: 30 }, y: { align: "center" } },
        { x: { align: "center", dx: 30 }, y: { align: "center" } }
    ],

    sizes: [
        {
            width: 650,
            height: 230
        },
        {
            width: 250,
            height: 700
        },
        {
            width: 250,
            height: 660
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.UI.MovesView, {
    position: [{
        x: { align: "center", dx: -240 },
        y: { align: "center", dy: 15 }
    }, {
        x: { align: "center", dx: -240 },
        y: { align: "center", dy: 15 }
    }, {
        x: { align: "center", dx: -15 },
        y: { align: "center", dy: 260 }
    }],

    text: {
        margin: { x: 0, y: 20 }
    },

    content: {
        y: -6
    },

    margin: [
        { x: { align: "center", dx: -226 }, y: -104 },
        { x: { align: "center", dx: -226 }, y: -104 },
        { x: 500, y: -104 }
    ],

    icon: {
        x: { align: "center" },
        y: -53
    },

    background: {
        scale9: cleverapps.UI.Scale9Rect.TwoPixelXY,
        width: 180,
        height: 180,
        x: { align: "center" },
        y: { align: "center" }
    }
});

cleverapps.overrideStyles(cleverapps.styles.GoalsView, {
    positionNoMission: [{
        x: { align: "center", dx: 95 },
        y: { align: "center", dy: 45 }
    }, {
        x: { align: "center", dx: 95 },
        y: { align: "center", dy: 45 }
    }, {
        x: { align: "center", dx: -38 },
        y: { align: "center", dy: -95 },
        rotation: -90
    }],

    positionWithMission: [{
        x: { align: "center", dx: 30 },
        y: { align: "center", dy: 40 }
    }, {
        x: { align: "center", dx: 30 },
        y: { align: "center", dy: 40 }
    }, {
        x: { align: "center", dx: -38 },
        y: { align: "center", dy: -20 },
        rotation: -90
    }],

    sizes: {
        noMission: [{
            height: 150,
            width: 440
        }, {
            height: 150,
            width: 440
        }, {
            height: 136,
            width: 460
        }],

        withMission: [{
            height: 130,
            width: 350
        }, {
            height: 130,
            width: 350
        }, {
            height: 130,
            width: 350
        }]
    }
});

cleverapps.overrideStyles(cleverapps.styles.HardLevelIcon, {
    enabled: true,
    scale: [0.59, 0.65, 0.55],
    alignment: [
        { x: { align: "center", dx: -285 }, y: { align: "top", dy: -123 } },
        { x: { align: "center", dx: -330 }, y: { align: "top", dy: -108 } },
        { x: { align: "left", dx: 100 }, y: { align: "center", dy: 340 } }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.MissionAnimationView, {
    flyTo: {
        second: {
            y: 15
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackWindow, {
    Title: {
        fitTo: {
            width: 360,
            height: 70
        },
        dx: 10,
        dy: 280,
        delay: 0.5,
        duration: 0.675
    },

    discountLabel: {
        dx: 242,
        dy: 317,
        delay: 1,
        duration: 0.675,
        fitTo: {
            width: 100
        }
    },

    animation: {
        "default": {
            idleDelay: undefined,
            dx: 0,
            dy: 0
        },

        "starterPack0": {
            idleAnimation: "0_idle",
            introAnimation: "0_start"
        },

        "starterPack": {
            idleAnimation: "1_idle",
            introAnimation: "1_start"
        },

        "starterPack2": {
            idleAnimation: "2_idle",
            introAnimation: "2_start"
        }
    },

    rewards: {
        delay: 2.1,
        duration: 0.675,
        fitTo: { width: 100, height: 45 },

        positions: {
            "default": {
                "boosters": {
                    "5": {
                        x: -170,
                        y: 93
                    },
                    "6": {
                        x: 167,
                        y: 93
                    },
                    "7": {
                        x: 163,
                        y: -179
                    }
                },
                "hard": {
                    x: 0,
                    y: -116
                },
                "unlimitedLives": {
                    x: -176,
                    y: -179
                }
            }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassLevelReward, {
    positions: [
        { x: { align: "right", dx: -55 }, y: { align: "bottom", dy: -55 } },
        { x: { align: "right", dx: 65 }, y: { align: "top", dy: -30 } },
        { x: { align: "center", dx: 0 }, y: { align: "bottom", dy: -60 } }
    ],

    prize: {
        text: {
            x: { align: "right", dx: -10 },
            y: { align: "bottom", dy: 10 }
        }
    }
});

cleverapps.styles.PassLevelReward.prize.icon.scale[PassLevelReward.BOOSTER] = 0.33;

cleverapps.overrideStyles(cleverapps.styles.InfoPanel, {
    delay: 1
});

cleverapps.overrideStyles(cleverapps.styles.ControlsPanel, {
    delay: 3.6,

    sizes: [
        {
            width: 650,
            height: 230
        },
        {
            width: 250,
            height: 700
        },
        {
            width: 250,
            height: 660
        }
    ],

    position: [
        {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: -20 }
        },
        {
            x: { align: "right", dx: 0 },
            y: { align: "center", dy: 0 }
        },
        {
            x: { align: "right", dx: 0 },
            y: { align: "center", dy: 0 }
        }
    ],

    margins: [
        {
            left: 95,
            right: 95
        }
    ],

    background: {
        positions: [
            { x: { align: "center" }, y: { align: "center" } },
            { x: { align: "center", dx: 65 }, y: { align: "center" } },
            { x: { align: "center", dx: 65 }, y: { align: "center" } }
        ]
    }
});

cleverapps.overrideStyles(cleverapps.styles.InfoPanel, {
    position: [
        {
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: 0 }
        },
        {
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: 0 }
        },
        {
            x: { align: "left", dx: 0 },
            y: { align: "center", dy: 0 }
        }
    ],
    sizes: [{
        width: 710,
        height: 260
    }, {
        width: 710,
        height: 260
    }, {
        width: 230,
        height: 780
    }],

    background: {
        decors: false
    }
});

cleverapps.overrideStyles(cleverapps.styles.GoalsView, {
    startAnimation: {
        margin: -10,
        baseScale: 0,
        width: 520,
        offset: {
            x: -800,
            y: 50
        },
        goals: {
            scale: [1.1, 1.2, 1.3],
            flyOffset: [
                { x: 0, y: 0 },
                { x: 0, y: 0 },
                { x: -10, y: 0 }
            ]
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.UI.ScoreView, {
    bar: {
        offsetY: 0
    },

    percent: {
        x: 492,
        y: 26,
        scale: 0.4
    },

    stars: {
        offsetY: 7
    },

    modes: [
        {
            x: { align: "center", dx: 86 },
            y: { align: "top", dy: -178 },
            width: 400
        },
        {
            x: { align: "center", dx: 86 },
            y: { align: "top", dy: -178 },
            width: 400
        },
        {
            x: { align: "center", dx: 60 },
            y: { align: "center", dy: -105 },
            width: 440,
            rotation: -90
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.LoseWindow, {
    hardLevel: {
        x: { align: "center", dx: 220 },
        y: { align: "bottom", dy: 250 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LevelStartWindow, {
    coinsBg: {
        width: 600,
        height: 390
    }
});

cleverapps.overrideStyles(cleverapps.styles.LineBoosterView, {
    animation: {
        y: 500
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassProgress, {
    paddingX: 130,
    scroll: {
        offsetWidth: -40
    },
    line: {
        first: {
            x: { align: "center", dx: -20 }
        },
        second: {
            x: { align: "center", dx: -20 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LevelPassWindow, {
    "tickets": {
        x: { align: "left", dx: 20 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.CloversView, {
    width: 120,
    height: 130,

    text: {
        x: { align: "center", dx: 25 },
        y: { align: "center", dy: -25 }
    },

    position: [
        {
            x: { align: "center", dx: 270 },
            y: { align: "center", dy: 40 }
        },
        {
            x: { align: "center", dx: 270 },
            y: { align: "center", dy: 40 }
        },
        {
            x: { align: "center", dx: -38 },
            y: { align: "center", dy: -275 },
            rotation: 90
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.LoaderSceneBackground, {
    logoAnimation: {
        pos: {
            factorY: 0.83
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.HeroesScene, {
    margin: 10,

    background: {
        scale: 2.32
    },

    levelBlock: {
        x: -60,
        y: 110
    },

    buttons: {
        width: 200,
        height: 70
    },

    house: {
        separateFromBg: true
    },

    heroBuilding: {
        width: 120,
        height: 120,
        scale: 1.2
    },

    heroBuildings: [
        {
            color: "e",
            x: { align: "center", dx: -180 },
            y: { align: "center", dy: 320 }
        },
        {
            color: "d",
            x: { align: "center", dx: 180 },
            y: { align: "center", dy: 320 }
        },
        {
            color: "a",
            x: { align: "center", dx: -180 },
            y: { align: "center", dy: -30 }
        },
        {
            color: "b",
            x: { align: "center", dx: 180 },
            y: { align: "center", dy: -30 }
        },
        {
            color: "c",
            x: { align: "center", dx: -180 },
            y: { align: "center", dy: -380 }
        },
        {
            color: "w",
            x: { align: "center", dx: 180 },
            y: { align: "center", dy: -380 }
        }
    ]
});